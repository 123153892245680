import React from 'react';

const ApplicationStateContext = React.createContext({
    loading: false,
});

const ApplicationStateProvider = ({ children }) => {
    const [loading, setLoading] = React.useState(false);

    return (
        <ApplicationStateContext.Provider value={{ loading, setLoading }}>
            {children}
        </ApplicationStateContext.Provider>
    );
}
export { ApplicationStateContext, ApplicationStateProvider };