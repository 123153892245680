import { useState } from 'react';
import environment from '../environment/environment';
const useUser = () => {
    const standartUserData = { "roles": [{ "name": "Download", "isWhitelist": true, "urls": ["/getAllInfo"], "serviceId": "6396053196180ad7fb60bb34", "id": "6426b737f5039aef991752c3" }, { "name": "User", "isWhitelist": true, "urls": ["/heizdaten", "/temp", "/temp/*", "/tempchart", "/templatest/*"], "serviceId": "639a0d9778442fe6da658d0d", "id": "639a0de578442fe6da658d0e" }], "user": { "username": "nils", "roleIds": ["6396056796180ad7fb60bb36", "639a0de578442fe6da658d0e"], "id": "6426b737f5039aef991752c3" }, "services": [{ "name": "Gather Information", "insideUrl": "gather-information", "outsideUrl": "gi", "storeLogs": false, "id": "6396053196180ad7fb60bb34" }, { "name": "Heizkreisverteiler", "insideUrl": "midlum", "outsideUrl": "midlum", "storeLogs": false, "id": "639a0d9778442fe6da658d0d" }] };
    const [user, setUser] = useState(environment.production === true ? undefined : standartUserData);
    const [loggedIn, setLoggedIn] = useState(environment.production === true ? false : false);
    const [loginToken, setLoginToken] = useState(environment.production === true ? undefined : 'dGVzdHVzZXI6cGFzc3dvcmQ=');
    return [user, setUser, loggedIn, setLoggedIn, loginToken, setLoginToken];
};
export default useUser;
