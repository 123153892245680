import styles from './textContent.module.css';
import { useContext } from "react";
import {UserContext} from "../../Provider/UserProvider.js";
import axios from "axios";
import env from "../../environment/environment.js";

const TextContent = (props) => {

    const {loginToken, user} = useContext(UserContext);

    const onCopy = async () => {
        await navigator.clipboard.writeText(props.text);
    }

    const onDelete = async () => {
        await axios({
            method: 'delete',
            url: `${env.apiRoute}/deleteText`,
            headers: {
                Authorization: 'Basic ' + loginToken,
                "Content-Type": "application/json",
            },
            data: {
                userId: user.user.id,
                token: 'Basic ' + loginToken,
                id: props.id,
            }
        });
        props.refresh();
    }

    return(
            <div className={styles.textContent}>
                <button className={styles.button} onClick={onCopy}>
                    <svg data-name="Group 27733" viewBox="0 0 230.451 235.429" className={styles.copySvg}>
                        <path id="Path_25" data-name="Path 25" d="M1101.887,252.759h115.407a28.247,28.247,0,0,1,28.212,28.216v115.4a28.247,28.247,0,0,1-28.212,28.216H1101.887a28.247,28.247,0,0,1-28.211-28.216v-115.4A28.247,28.247,0,0,1,1101.887,252.759ZM1217.294,410.19a13.826,13.826,0,0,0,13.808-13.812v-115.4a13.826,13.826,0,0,0-13.808-13.812H1101.887a13.826,13.826,0,0,0-13.807,13.813v115.4a13.826,13.826,0,0,0,13.807,13.813Z" transform="translate(-1015.055 -189.165)"/>
                        <path id="Path_26" data-name="Path 26" d="M1133.962,266.005h89.5a34.289,34.289,0,0,1,34.255,34.251v3.072a7.2,7.2,0,1,1-14.4,0v-3.072a19.875,19.875,0,0,0-19.853-19.848h-89.5a33.716,33.716,0,0,0-33.674,33.674v89.506a19.873,19.873,0,0,0,19.848,19.848,7.2,7.2,0,0,1,0,14.4,34.291,34.291,0,0,1-34.252-34.251V314.083A48.131,48.131,0,0,1,1133.962,266.005Z" transform="translate(-1085.885 -266.005)"/>
                    </svg>

                </button>
                <p className={styles.textfield}>{props.text}</p>
                <button className={styles.button} onClick={onDelete}>
                    <svg version="1.1"
                         viewBox="0 0 482.428 482.429"
                         className={styles.deleteSvg}>
                        <g>
                            <g>
                                <path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098
                                c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117
                                h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828
                                C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879
                                C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096
                                c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266
                                c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979
                                V115.744z"/>
                                <path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07
                                c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"/>
                                <path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07
                                c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"/>
                                <path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07
                                c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"/>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
    )
}
export default TextContent;
