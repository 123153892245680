import Feed from './feed/Feed';
import TextInput from './AddContent/TextInput';
import useTexts from "../Hooks/useTexts";
const Home = () => {
    const [texts, loading, error, refresh] = useTexts();
    const textAdded = () => {
        refresh();
    }
    return <><Feed texts={texts} loading={loading} error={error} refresh={refresh}/><TextInput textAdded={textAdded}/></>;
};
export default Home;
