import axios from "axios";
import env from "../environment/environment";
import { useState, useContext } from "react";
import { UserContext } from "../Provider/UserProvider";

const useUpload = () => {

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const { loggedIn, loginToken, user } = useContext(UserContext);

    const newText = async (text) => {
        setLoading(true);
        if (!loggedIn) {
            setError("Not logged in");
            return;
        }
        try {
            await axios({
                method: 'post',
                url: env.apiRoute + '/addText',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + loginToken,
                },
                data: {
                    text: text,
                    date: Date.now(),
                },
            })
            setLoading(false);
        } catch (err) {
            setError(err);
        }
    }

    return [newText, loading, error];
}

export default useUpload;
