import UserProvider from './UserProvider';
import { RouterProvider } from 'react-router-dom';
import LoginRouter from '../Router/Router';
import { ApplicationStateProvider } from './ApplicationStateProvider';
const ProviderComponent = ({children}) => {
    return (
        <ApplicationStateProvider>
            <UserProvider>
                <RouterProvider router={LoginRouter}>
                    {children}
                </RouterProvider>
            </UserProvider>
        </ApplicationStateProvider>
       
    );
};
export default ProviderComponent;