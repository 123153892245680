import React from 'react';
import useUser from '../Hooks/useUser';
import axios from 'axios';
import env from '../environment/environment';

const UserContext = React.createContext({
    user: undefined,
    loggedIn: false,
    loginToken: undefined,
    login: () => {},
});

const UserProvider = ({ children }) => {
    const [user, setUser, loggedIn, setLoggedIn, loginToken, setLoginToken] = useUser();

    const login = async (credentials) => {
        const loginPromise = new Promise((resolve) => {
            if (env.production === false) {
                setLoggedIn(true);
                setLoginToken('dGVzdHVzZXI6cGFzc3dvcmQ=');
                resolve(true);
            }
            const pullUserInfo = (token) => {
                return axios({
                    method: 'get',
                    url: env.apiServiceRoute + '/trylogin',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + token, 
                    }
                });
            };

            if (credentials === '') {
                return;
            }

            pullUserInfo(credentials).then((response) => {
                if (response.status === 200 && response.data.services.filter((service) => service.insideUrl === 'clipboard').length > 0) {
                    const token = credentials;
                    localStorage.setItem('user', token);
                    setLoginToken(token);
                    setUser(response.data);
                    setLoggedIn(true);
                    resolve(true);
                }
                else {
                    console.error(response);
                    localStorage.removeItem('user');
                    resolve(false)
                }
            }).catch((error) => {
                console.error(error);
                localStorage.removeItem('user');
                resolve(false);
            });
        });
        return loginPromise;
    };
    return (
        <UserContext.Provider value={{ user, loggedIn, loginToken, login }}>
            {children}
        </UserContext.Provider>
    );
}
export default UserProvider;
export { UserContext };
