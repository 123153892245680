import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import env from '../environment/environment';
import {UserContext} from "../Provider/UserProvider";
import {ApplicationStateContext} from "../Provider/ApplicationStateProvider";
const useTexts = () => {
    const [texts, setTexts] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);

    const { setLoading: setAppLoading } = useContext(ApplicationStateContext);

    const { loggedIn, loginToken, user } = useContext(UserContext);

    if (!loggedIn) {
        setError("Not logged in");
    }

    const refresh = async () => {
        setLoading(true);
        setAppLoading(true);
        try {
            const res = await axios({
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + loginToken,
                },
                method: 'post',
                url: env.apiRoute + '/allTexts',
            })
            setLoading(false);
            setAppLoading(false);
            setTexts(res.data);
            setError(undefined);
        } catch (err) {
            setLoading(false);
            setAppLoading(false);
            setError(err);
        }
    }

    useEffect(() => {
        if (!loggedIn) {
            setError("Not logged in");
            return;
        }
        refresh().then((data) => {});

    }, [loggedIn, loginToken]);

    return [texts, loading, error, refresh];
}

export default useTexts;
