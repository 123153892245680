import { createBrowserRouter } from 'react-router-dom';
import Content from '../Components/Content';
import Login from '../Components/Login';
import Home from '../Components/Home'
import ErrorPage from '../Components/ErrorPage';
import LoggedInGuard from '../Security/LoggedInGuard'; 
const router = createBrowserRouter([
    { path: '/', element: <Content />, errorElement: <ErrorPage />, children: [
        { path: '/', element: <LoggedInGuard />, children: [
            {path: '/', element: <Home />},
        ]},
    ]},
    { path: '/login', element: <Login />}
]);
export default router;
