import { useState } from 'react';
import useUpload from '../../Hooks/useUpload';
import styles from './textInput.module.css';
const TextInput = (props) => {

    const [text, setText] = useState('');
    const [newText, loading, error] = useUpload();

    const sendHandler = () => {
        newText(text).then(() => props.textAdded());
        setText('');
    }

    const keyDownHandler = (e) => {
        if (e.key === 'Enter') {
            sendHandler();
        }
    }

    return <div className={styles.textInputWrapper}>
        <input type="text" className={styles.textInput} value={text} onKeyDown={keyDownHandler} onChange={(e) => setText(e.target.value)} />
        <button className={styles.sendButton} onClick={sendHandler}>Add</button>
    </div> ;
}
export default TextInput;
